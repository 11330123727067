<template>
    <div style="background-color: white;min-height: 650px;padding: 20px;box-sizing: border-box;">
        <div style="display:none;align-items: center;">
            <i class="el-icon-arrow-left font-bold text-xl cursor-pointer" style="color: #999999" @click="goBack" />
            <p class="passname">{{ passInfo.name }}</p>
        </div>

        <div class="passdetail-content">
            <div class="middle-content">
                <p class="passname font-bold" style="font-size: 28px; margin-left: 0px; display: inline-block;">{{ passInfo.name }}</p>
                <p v-if="passInfo.short_intro" class="short-intro" style="font-size: 16px; color: #666; margin-top: 10px; margin-bottom: 20px;">{{ passInfo.short_intro }}</p>
                
                
            </div>
            <div class="right-content">
                <p v-if="checkEnablePurchase()" class="deadline-note" style="font-size: 14px; color: #666; margin-bottom: 10px;">
                    Available before<br> {{ formatDeadlineDate(passInfo.deadline_date) }}
                </p>
                <p v-else class="deadline-note" style="font-size: 14px; color: #666; margin-bottom: 10px;">
                    Unavailable after<br> {{ formatDeadlineDate(passInfo.deadline_date) }}
                </p>
                <div :class="checkEnablePurchase() ? 'btn-purchase' : 'btn-purchase btn-purchase-disabled'"
                @click="doPurchase" style="position: relative; top: 0; right: 0;">{{ 'Purchase' }}</div>
                    <p class="note" style="font-size: 14px; color: var(--c-primary); margin-top: 10px;">
                        By paying {{ studentPrice }} {{ passPrice.currency }}
                    </p>
                    <p class="note" style="font-size: 14px; color: var(--c-primary); margin-top: 5px;">
                        <span style="text-decoration: line-through;">{{ standardPrice }} {{ passPrice.currency }}</span>
                    </p>
                </div>
            
        </div>
        <div style="margin-top: 20px;">
        <p class="title">How We Calculate Your Fees</p>
        <div class="passdetail-content">
            
            <div class="middle-content" style="width: 100%;">
                    
                
                <div style="display: flex; align-items: stretch; width: 100%; ">
                    <div style="flex: 4;">
                        <div class="price-container" style="display: flex;align-items: center;margin-left: 0px;">
                            <p style="text-align: center; margin-left: 10px; display: flex; flex-direction: column;">
                            <span style="color: var(--c-primary);">Standard<br>Fees</span>
                            <span class="font-light" style="color: var(--c-primary);">{{standardPrice}} {{passPrice.currency}}</span>
                            </p>
                        <div style="font-size: 24px; color: var(--c-primary); margin: 0 10px;">-</div>
                        
                        <p style="text-align: center;margin-left: 10px;">
                            <span style="color: var(--c-primary);">Eligible Fee Reduction<br>for You</span>
                            <span class="font-light" style="color: var(--c-primary);">{{reductionPrice}} {{passPrice.currency}}</span>
                        </p>
                        <div style="font-size: 24px; color: var(--c-primary); margin: 0 10px;">=</div>
                        <p style="text-align: center;margin-left: 10px;">
                            <span style="color: var(--c-primary);">Fees You Need to Pay<br>after Reduction</span>
                            <span class="font-light" style="color: var(--c-primary);">{{studentPrice}} {{passPrice.currency}}</span>
                        </p>

                        
                        
                    </div>
                    <div style="margin-top:20px;font-size:14px;font-family:centurygothic;line-height:150%">
                            <p>1. The Fee Reduction Policy aims to support students by providing financial relief and ensuring that talent and merit are the key factors for success.</p>
                            <p>2. Guided by principles of fairness and need-based support, the policy considers economic indicators and tuition fee structures to determine the level of reduction.</p>
                            <p>3. A multi-tiered fee reduction policy is implemented with eight levels, based on a formula that considers Country/Region Class and Tuition Tier, while a registration fee covers operational costs.</p>

                        </div>
                    </div>
                    <div style="flex: 3; border-left: 1px solid #e0e0e0; padding-left: 20px; margin-left: 20px;">
                        <div class="fee-reduction-info">
                            <h3 style="font-size: 18px; color: var(--c-primary); margin-bottom: 10px;">Your Fee Reduction Details</h3>
                            <p><strong>Your Country Tier:</strong> Tier {{ levelResult.country_level }} ({{ levelResult.account_country }})</p>
                            <p><strong>Your Tuition Tier:</strong> Tier {{ levelResult.finish_level }} ({{ tuition_tiers[levelResult.finish_level] }})</p>
                            <p><strong>Your Fee Reduction Tier:</strong> Tier {{ levelResult.reduction_level }} ({{ reductionPrice }} {{ passPrice.currency }})</p>
                        </div>
                        <p class="title" style="margin: 10px 0px;">Reminder</p>
                <div class="reminder-container" style="margin-bottom: 20px;">
                    <p class="reminder-content" style="font-size: 14px; font-family: centurygothic; line-height: 150%;">
                        If you notice that the applicable fee reduction is incorrect according to the fee reduction policy, please email us at <a href="mailto:contact@eae.online">contact@eae.online</a> for modification. For details on the specific fee reduction policy, refer to the information below.
                    </p>
                    <!--v-if="passInfo.BU == 'WAO'"-->
                    <div  class="reminder-content">
                        <p style="margin-top: 10px;">
                            <a href="https://ap.learningfirst.tech/EAE%20Fee%20Reduction%20Policy.pdf" target="_blank" style="color: #0000EE; text-decoration: underline; cursor: pointer;">Fee Reduction Policy</a>
                        </p>
                        <!-- <el-button type="primary" style="margin-top: 10px;" size="small" @click="gotoTierView()">Learn more </el-button> -->
                    </div>
                </div>
                    </div>
                </div>
            </div>
        </div> 
        </div>
        <div style="margin-top: 30px;display: flex;">
            <div style="flex: 1.5;">
                <p class="title">Introduction</p>
                <p v-html="passInfo.intro" class="intro_container ql-snow ql-editor"></p>
            </div>
            <div style="width: 40px;">

            </div>
            <div style="flex: 1;">
                <p class="title">Pass Expiry Date</p>
                
                <div v-html="formatExpiredInfo()" class="reminder-container" style="margin-bottom: 20px;"></div>
                
                <p class="title" style="margin-bottom: 10px;">Recommendations</p>


                <!-- <div class="announce-container">
                    <div v-for="(announce, index) in news" :key="index" class="announce-item">
                        <p class="announce-time">{{ $dayjs(announce.update_time * 1000).format('MMM dd,YYYY') }}</p>
                        <p class="announce-title">{{ announce.title }}</p>
                        <p class="announce-content" v-html="announce.content"></p>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import {
    createREPassOrder,
    getStudentReductionLevelByProject,
    getPassPrice,
    getPass,
    postRequest
} from '../../api/eae'
import {
    getUserId
} from '../../utils/store'
import '../../assets/common/common.css'
import '../../assets/common/font.css'
import {
    formatDateUTC
} from '../../utils/date'
export default ({
    name: 'index',
    data() {
        return {
            pass_id: this.$route.query.pass_id,
            passInfo: {},
            passPrice: {},
            studentPrice:  '',
            reductionPrice: '',
            standardPrice: '',
            levelResult: {},
            tuition_tiers: {
                1: 'Exceeding 11,000 USD per year',
                2: 'Exceeding 6,000 USD per year',
                3: 'Exceeding 3,000 USD per year',
                4: 'Exceeding 1,500 USD per year',
                5: 'Exceeding 1,100 USD per year',
                6: 'Exceeding 750 USD per year',
                7: 'Exceeding 350 USD per year',
                8: 'Less than 350 USD per year but greater than 0',
                9: 'No tuition fees charged'
            },
        }
    }, mounted() {
        this.fetchData()
    },
    methods: {

        gotoTierView(){
            this.$router.push({
                path:'/home/student-tier',
                query:{
                    pass_id:this.pass_id
                }
            })
        },
        convertToUTCTime(time) {
            var date = new Date(time)
            return formatDateUTC(date, 'yyyy-MM-dd hh:mm')
        },
        checkEnablePurchase() {
            if (this.passInfo && this.passInfo.deadline_date && this.passInfo.deadline_date) {
                let deadline_date = this.passInfo.deadline_date + 24 * 60 * 60 * 1000 - 1
                // console.log('deadline_date :' + deadline_date)
                var shiqi = this.convertToUTCTime(deadline_date)
                // console.log('shiqi :' + shiqi)
                let new_ddl_time  = parseInt(new Date(shiqi).getTime())
                let current_time = this.getUTCTime(0).getTime()
                // console.log('current_time :' + current_time)
                // console.log('new_ddl_time :' + new_ddl_time)
                if (new_ddl_time > current_time) {
                    return true
                }
                return false
            }
            return true
        },
        getUTCTime(data){
            var time=new Date(); //获取时间
            // 获取时间偏移量 getTimezoneOffset 获取格林威治时间   *60000是到毫秒
            var dataOffset=new Date().getTimezoneOffset()*60000
            // 获取格林威治时间 
            var utc=time.getTime()+dataOffset;  // 两个时间戳
            // 拿格林威治时间去反推指定地区时间    
            var newTime=utc+3600000*data;  // 
            var times=new Date(newTime);
            return times
        },

        formatExpiredInfo() {
            if (this.passInfo.expired_type == 'expired_by_days') {
                return this.passInfo.expired_value + ' days from the < date of purchase'
            }
            return '<span style="font-family: centurygothic">'+ this.$dayjs(this.passInfo.expired_value).format('MMMM DD, YYYY') + '</span>'
        },

        async fetchData() {
            const loading = this.$loading({
              lock: true,
              text: "Loading",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            let passResult = await getPass(this.pass_id)
            this.passInfo = passResult.data
            let levelResult = await postRequest({
              function: "getStudentReductionLevelsByProject",
              student_id: getUserId()
            })
            this.levelResult = levelResult.data
            let studentLevel = this.levelResult.reduction_level

            let passPriceResult = await getPassPrice(this.pass_id)
            this.passPrice = passPriceResult.data
            this.standardPrice = this.passPrice.standard_price
            for (let i = 0; i < this.passPrice.prices.length; i++) {
                let item = this.passPrice.prices[i]
                if (item.level == studentLevel) {
                    this.studentPrice = item.price
                }
            }
            this.reductionPrice = this.passPrice.standard_price - this.studentPrice
            loading.close();
        },
        doPurchase() {
            if(this.checkEnablePurchase() == false){
                console.log('不可以购买')
                return
            }
            createREPassOrder(getUserId(), this.pass_id,'EAE-PASS').then((res) => {
                let result = res.data
                if(result.code == -2 || result.code == -3 || result.code == -4 ){
                    this.$message.warning(result.msg)
                    return
                }
                 //统一放到支付页面处理
                this.gotoOrderView(result.data._id.$id)
                // if (result.code == 0) {
                //     this.gotoOrderView(result.data._id.$id)
                // } else if (result.code == -1) {
                //     let order_status = result.data.status
                //     if (order_status == '未支付') {
                //         this.gotoOrderView(result.data._id.$id)
                //     } else {
                //         this.$message.success('You have already purchased this pass')
                //     }
                // } else {
                //     this.$message.warning('You have already purchased this pass')
                // }
            })
        },
        gotoOrderView(order_id) {
            this.$router.push({
                path: '/home/pass-order',
                query: {
                    order_id: order_id
                }
            })
        },
        goBack() {
            this.$router.go(-1)
        },
        formatDeadlineDate(date) {
            if (!date) return '';
            const formattedDate = this.$dayjs(date).format('MMM DD, YYYY');
            return formattedDate;
        },
    }
})
</script>
<style scoped lang="scss">
.passdetail-content {
    margin-top: 20px;
    width: 100%;
    border: 1px solid #E6D7D7;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px;
    display: flex;

    .type-logo {
        width: 188px;
        height: 188px;
        object-fit: cover;
    }

    .middle-content {
        padding: 0px 10px;
        box-sizing: border-box;

        .price-container {
            height: 80px;
            border-radius: 5px;
            padding: 15px;
            box-sizing: border-box;
            color: var(--c-primary);
            font-size: 16px;
            line-height: 24px;
            font-family: centurygothic_bold;
            background-color: #FFF1F1;
            span{
              display: block;
            }
        }


    }

    .right-content {
        flex: 1;
        text-align: right;
        padding: 0px 10px;
        box-sizing: border-box;

        .btn-purchase {
            padding: 10px 25px;
            display: inline-block;
            cursor: pointer;
            text-align: center;
            background-color: var(--c-primary);
            color: white;
            border-radius: 5px;
            font-size: 20px;
            font-family: centurygothic_bold;
        }
        .btn-purchase-disabled {
            background-color: #e2e2e2;
        }
        .desc {
            color: #4A0700;
            font-size: 14px;
            font-family: centurygothic;
            line-height: 20px;
        }

    }
}

.passname {
    margin-left: 10px;
    color: #333;
    font-size: 20px;
    font-family: centurygothic_bold;
}

.title {
    color: #333;
    font-size: 16px;
    font-family: centurygothic_bold;
    margin-bottom: 15px;
}

.intro_container {
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid #F0E1E1;
    font-size: 14px;
    color: #333;
    line-height: 22px;
    padding: 20px 20px;
    font-family: centurygothic;
}
.font-light{
  height: 24px;
  font-family: centurygothic;
}
</style>
